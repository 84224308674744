import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import "./CSS/DonationPaymentModal.css";

const DonationPaymentModal = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    amount: ""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  
  //   try {
  //     const response = await axios.post("http://localhost:5000/send-donation-form", formData);
      
  //     // Check if response.data.url is not undefined
  //     if (response.data.url) {
  //       window.location.href = response.data.url;
  //     } else {
  //       console.error("Payment URL not received from server");
  //     }
  //   } catch (error) {
  //     console.error("Payment initiation error:", error);
  //   }
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post("http://localhost:5000/initiatePayment", new URLSearchParams(formData), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      
      if (response.data.url) {
        window.location.href = response.data.url; // Redirect to the payment URL
      } else {
        console.error("Payment URL not received from server");
      }
    } catch (error) {
      console.error("Payment initiation error:", error.response ? error.response.data : error.message);
    }
  };



  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Donation Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name" className="form-label">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone" className="form-label">Phone</label>
            <input
              type="tel"
              className="form-control"
              id="phone"
              placeholder="Enter your phone number"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="amount" className="form-label">Amount</label>
            <input
              type="number"
              className="form-control"
              id="amount"
              placeholder="Enter the amount"
              value={formData.amount}
              onChange={handleChange}
              required
            />
          </div>

          <div className="text-center">
            <button type="submit" className="btn btn-primary">Donate</button>
            <Button variant="secondary" className="ml-2" onClick={handleClose}>Close</Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default DonationPaymentModal;
